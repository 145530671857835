/**
 * Variables for spacing (like margin and padding) and sizing (like breakpoints
 * and content width)
 */

$thin-width: 800px;
$page-width: 1000px;
$max-cont-width: 1200px;

// Breakpoints
$tablet-max-width: 1200px;
$mobile-max-width:  800px;                    // 800px
$desktop-min-width: $mobile-max-width + 1;    // 801px

