@use 'variables/colors' as *;

.footer {
  width: 100%;
  padding: 15px 0px;
  bottom: 0px;
  left: 0;
  background-color: var(--dark-bg-color);
  box-shadow: 0px 0px 5px $shadow-color;
}

.social-bar {
  font-size: 0px;
  text-align: center;
  padding: 0;

  li { display: inline-block; }
}

.social-bar a {
  display: block;
  text-decoration: none;
  margin: 0px 10px;
}

.social-bar .icon {
  display: inline-block;
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 45px;
  height: 45px;
  background-color: $shadow-color;
  border-radius: 10rem;
}

.social-bar .icon {
  background-image: url("../images/social-media-icons/icons.svg");

  &.github { background-position: 0 0%; }
  &.bluesky { background-position: 0 100%; }
  &.linkedin { background-position: 0 50%; }
}
