// Generic (HTML) colors
$white:           #FFFFFF; // white
$off-white:       #F3F3F3;
$off-white-dark:  #E6E6E6;
$light-grey:      #D3D3D3; // lightgrey
$mid-grey:        #DCDCDC; // gainsboro
$grey:            #808080; // grey
$dark-grey:       #404040;
$mid-black:       #1f1f1f;
$off-black:      #171717;

// Greys used for text
$text-light:      #686767; // A light grey that is WCAG compliant
$text-grey:        #272727;

$shadow-color: rgba(0, 0, 0, 0.5);
$light-shadow-color: rgba(0, 0, 0, 0.25);

// Brand colors
$light-red:     #FFE8EC;
$brand-red:     #D80000;
$dark-red:      #A30000;
$darkest-red:   #700000;

$pink:          #FF8A8A;
$pink-light:    #FFA8A8;

$brand-orange: #FF7400;
$brand-blue: #0059FF;

/**
 * IMPORTANT: Most of these are exposed as CSS variables in main.scss
 */
