@use 'variables/colors' as *;

$header-mob-height: 65px;

.site-header {
  position: fixed;
  top: 0px;
  width: 100%;
  text-align: left;
  background: var(--brand-bg-color);
  z-index: 5;
  font-size: 20px;

  .header-main { display: inline-block; }

  ul {
    list-style: none;
    margin: 0;

    li { display: inline; }
  }
}

.header-inside {
  box-sizing: border-box;
  height: 100%;
  padding: 15px 0;
  color: $white;
  font-weight: 400;
}

.site-header {
  a {
    color: $white;
    border-radius: 8px;
    transition: color 0.2s, padding 0.2s, background 0.2s;
    padding: 5px 15px;

    // Accessibly hide underline so high contrast mode can bring it back
    &, &:hover, &:focus { text-decoration-color: transparent; }

    &:hover, &:focus, &.hire-me {
      color: $dark-grey;
      background-color: $white;
    }

    &.name { font-weight: 600;  }
  }

  a.skip-to-main.sr-only {
    top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &:focus { left: 45px; }
  }

  // Make header links and buttons have clear focus
  a:focus, button:focus {
    outline: dashed 3px $white;
  }
}

.header-buttons { float: right; }

.header-buttons li + li { margin-left: 5px; }

.mob-menu-btn {
  display: none;
  box-sizing: border-box;
  cursor: pointer;
  width: 45px;
  height: 45px;
  padding: 5px;
  background: none;
  border: none;

  img {
    display: block;
    height: 100%;
    width: 100%;
  }
}

/**
 * Tablet-ish & mobile styling
 */
@media (max-width: 800px) {
  .header-inside {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

/**
 * Mobile styling
 */
@media (max-width: 600px) {
  body { padding-top: $header-mob-height !important; }

  .mob-menu-btn {
    display: block;
    // By setting the image via CSS, it's only loaded if this media query is hit
    background-image: url('/images/menu.svg');
    background-size: auto 100%;
  }

  .site-header {
    height: $header-mob-height;
    overflow: hidden;
    font-size: 22px;
    // Setup transparent border for fade in on open
    border-bottom: solid 3px transparent;
    // Animate height and border
    transition: height 0.3s, border-color 0.3s;

    &:not(.-open) {
      .header-buttons { display: none; }
    }
    &.-open {
      height: $header-mob-height * 2 + 5px;
      border-color: $white;
    }

    .header-inside { padding: 0; }

    .header-main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px 0;
    }

    .header-buttons {
      float: none;
      padding: 15px 0 20px 0px;

      li + li { margin-left: 10px; }
    }
  }

  .header-buttons {
    width: 100%;
    padding-top: 0;
    text-align: center;

    li + li { margin-left: 30px; }
  }
}
